import { useCallback, useState, useEffect, useRef } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { FetchResult } from '@apollo/client'
import { useApollo } from 'lib/apolloClient'
import isNil from 'lodash.isnil'
import omitBy from 'lodash.omitby'
import classnames from 'classnames'

import { useToast } from 'components/Toast'
import Icon from 'components/Icon'
import { useMutation } from 'hooks/useMutation'
import { useShoppingCart } from '../ShoppingCartContext/useShoppingCart'
import { OrderCheckoutVarietyFragment } from '../ShoppingCartContext/graphql/__generated__/OrderCheckoutVarietyFragment'
import { OrderCheckoutFragment } from '../ShoppingCartContext/graphql/__generated__/OrderCheckoutFragment'

import GET_OLDEST_DRAFT_AND_LAST_POSTED_ORDERS from 'modules/buyer-hub/ordering/components/ReorderBanner/graphql/GetOldestDraftAndLastPostedOrders.graphql'
import GET_DRAFT_ORDERS_SUMMARY from 'modules/buyer-hub/checkout/components/ShoppingCartContext/graphql/GetDraftOrdersSummary.graphql'
import { GetDraftOrdersSummary } from 'modules/buyer-hub/checkout/components/ShoppingCartContext/graphql/__generated__/GetDraftOrdersSummary'
import GET_DRAFT_ORDERS from '../ShoppingCartContext/graphql/GetDraftOrders.graphql'
import CREATE_DRAFT_ORDER_MUTATION from './graphql/CreateDraftOrderMutation.graphql'
import UPDATE_DRAFT_ORDER_LINE_ITEM_MUTATION from './graphql/UpdateDraftOrderLineItemMutation.graphql'
import {
  CreateDraftOrderMutation,
  CreateDraftOrderMutationVariables
} from './graphql/__generated__/CreateDraftOrderMutation'
import {
  UpdateDraftOrderLineItemMutation,
  UpdateDraftOrderLineItemMutationVariables
} from './graphql/__generated__/UpdateDraftOrderLineItemMutation'
import { BuyerStatusEnum } from '../../../../../../__generated__/globalTypes'

import styles from './AddToCartButton.module.css'

export interface IOrderLineItem {
  id?: string
  varietyId: string
  productId: string
  quantity: number
}

export type Variety = Pick<OrderCheckoutVarietyFragment, 'id' | 'boxQuantity' | 'minimumOrderQuantity'>

export type AddToCartButtonProps = {
  sellerId: string
  catalogId: string
  productId?: string
  orderId?: string
  orderLineItemId?: string
  variety?: Variety
  isStack?: boolean
  handleStackClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onLineItemChange?: (lineItem: IOrderLineItem | undefined | null) => void
  expand?: boolean
  disabled?: boolean
  hasRemovedOutOfStockItems?: boolean
  setHasRemovedOutOfStockItems?: (hasRemovedOutOfStockItems: boolean) => void
  handleHasRemovedOutOfStockItems?: () => void
}

type UpdateCache = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: FetchResult<UpdateDraftOrderLineItemMutation, Record<string, any>, Record<string, any>>
  productId: string
  varietyId: string
}

type UpdateCacheOptions = {
  mode?: 'update' | 'create'
  originalQuantity?: number
  desiredQuantity?: number
}

const AddToCartButton = ({
  sellerId,
  catalogId,
  productId,
  orderId,
  orderLineItemId,
  variety,
  isStack = false,
  expand = true,
  handleStackClick,
  onLineItemChange,
  disabled = false,
  hasRemovedOutOfStockItems = false,
  setHasRemovedOutOfStockItems
}: AddToCartButtonProps) => {
  const { cache } = useApollo({})
  const varietyId = variety?.id
  const [showToast] = useToast()

  // Component State
  const order = useRef<OrderCheckoutFragment | undefined | null>(undefined)
  const [orderLineItem, setOrderLineItem] = useState<IOrderLineItem | undefined | null>(undefined)

  // Fetches the order as specified by orderId. Used by CartPage
  const {
    fetchOrder,
    fetchOrderResult: { loading: orderLoading, data: orderData }
  } = useShoppingCart()

  // Or fetches all draft orders for the seller. Used by SellerPage
  const {
    fetchOrders,
    fetchOrdersResult: { loading: ordersLoading, data: ordersData, refetch: refetchOrders }
  } = useShoppingCart()

  const {
    fetchOrdersSummaryResult: { refetch: refetchSummary }
  } = useShoppingCart()

  // Creates a new draft order for the catalog, if one doesn't exist
  const [createDraftOrder] = useMutation<CreateDraftOrderMutation, CreateDraftOrderMutationVariables>(
    CREATE_DRAFT_ORDER_MUTATION,
    {
      toastOptions: {
        suppress: true
      }
    }
  )

  // Creates/updates/deletes an order line item
  const [updateDraftOrderLineItem] = useMutation<
    UpdateDraftOrderLineItemMutation,
    UpdateDraftOrderLineItemMutationVariables
  >(UPDATE_DRAFT_ORDER_LINE_ITEM_MUTATION)

  // Fetch the order data when component first mounts
  useEffect(() => {
    const loadData = async () => {
      if ((orderId && orderData == null) || (sellerId && ordersData == null)) {
        const variables = omitBy({ orderId, sellerId }, isNil)

        // This is nessesary to improve Apollo caching of the query results
        if (orderId) {
          delete variables.sellerId
          await fetchOrder({ variables })
        } else {
          await fetchOrders({ variables })
        }
      }
    }

    loadData()
  }, [sellerId, orderId, fetchOrder, fetchOrders, orderData, ordersData])

  // Load the order and initial orderLineItem value
  useEffect(() => {
    // Do not update the order again. It doesn't matter if it becomes out of
    // sync here, because we have a separate local orderLineItem state, which is
    // the primary source of state for this component

    // `order.current` prevents `quantityInput` from updating when RemoveOutOfStockItemsMutation is executed resulting to stale quantity values.
    // Added flag to allow updates only after the mutation was called, or when a product has issue(outOfStock, unavailable, etc).
    if (order.current) {
      if (hasRemovedOutOfStockItems) {
        setHasRemovedOutOfStockItems?.(false)
      } else {
        return
      }
    }

    const o = (
      !orderLoading && orderId != null && orderData != null
        ? orderData.currentBuyer?.order
        : !ordersLoading && sellerId != null
        ? ordersData?.currentBuyer?.orders?.nodes?.find(order => order?.catalog?.id === catalogId)
        : null
    ) as OrderCheckoutFragment

    const l =
      (productId &&
        varietyId &&
        o?.orderLineItems.find(line =>
          Object.entries({ productId, varietyId }).every(([key, value]) => line[key as keyof typeof line] === value)
        )) ||
      (!productId && !varietyId && o?.orderLineItems.find(line => line.id === orderLineItemId)) ||
      null

    order.current = o

    setOrderLineItem(l)
    onLineItemChange?.(l)
  }, [
    orderId,
    orderLineItemId,
    sellerId,
    catalogId,
    orderLoading,
    orderData,
    ordersLoading,
    ordersData,
    productId,
    varietyId,
    onLineItemChange,
    hasRemovedOutOfStockItems,
    setHasRemovedOutOfStockItems
  ])

  const inOrder = orderLineItem != null
  const minQuantityStart = variety?.minimumOrderQuantity || variety?.boxQuantity || 1
  const minQuantityChange = variety?.boxQuantity || 1

  // Create a new draft order for the catalog, if one doesn't exist
  const setupDraftOrder = useDebouncedCallback(
    useCallback(async () => {
      if (order.current) return false
      const result = await createDraftOrder({
        variables: { input: { catalogId, attributes: { lines: [] } } },
        onCompleted: async data => {
          const success = data.createOrder?.success
          if (!success) {
            if (data.createOrder?.errors?.[0].code === 'draft-order-already-exists') {
              await refetchOrders()
              await refetchSummary()
            } else {
              showToast({ kind: 'error', message: 'Failed to create draft order' })
            }
          }
        }
      })
      order.current = result.data?.createOrder?.order
      return true
    }, [catalogId, createDraftOrder, refetchOrders, refetchSummary, showToast]),
    // Have a large throttle time as a backup safety measure, so we can never
    // create duplicate orders when clicking buttons really fast
    5000,
    { leading: true }
  )

  // Mutate the components local state with the new order line item
  const updateCache = useCallback(
    (
      { result, varietyId, productId }: UpdateCache,
      { mode, originalQuantity, desiredQuantity }: UpdateCacheOptions
    ) => {
      if (result.data?.updateOrderLineItem.success) {
        const l = result.data?.updateOrderLineItem.orderLineItem
        const orderId = result.data?.updateOrderLineItem.order?.id
        if (l != null) {
          const line = { id: l.id, productId, varietyId, quantity: l.quantity }
          // Apollo cache already updated by return field on mutation
          setOrderLineItem(line)
          onLineItemChange?.(line)

          // Update the summary cache
          cache.updateQuery<GetDraftOrdersSummary>({ query: GET_DRAFT_ORDERS_SUMMARY }, data => {
            return {
              ...data,
              currentBuyer: {
                ...data?.currentBuyer,
                orders: {
                  ...data?.currentBuyer.orders,
                  nodes: data?.currentBuyer.orders.nodes.map(order => {
                    const orderLineItem = order.orderLineItems.find(line => line.id === l.id)
                    const orderLineItems = [...order.orderLineItems]

                    if (!orderLineItem && order.id === orderId) {
                      orderLineItems.push({
                        __typename: 'OrderLineItem',
                        ...line
                      })
                    }

                    return { ...order, orderLineItems }
                  })
                }
              }
            } as GetDraftOrdersSummary
          })
        } else {
          if (mode === 'update' && orderLineItem != null && desiredQuantity === 0) {
            cache.evict({ id: `OrderLineItem:${orderLineItem.id}` })
            cache.gc()
            setOrderLineItem(null)
            onLineItemChange?.(null)
          }
        }

        const o = result.data?.updateOrderLineItem.order
        if (o != null && order.current != null) {
          order.current = {
            ...order.current,
            totalAmount: o.totalAmount,
            taxAmount: o.taxAmount,
            grandTotal: o.grandTotal
          }
          // TODO: update the line item on the order.
        }
      } else {
        // Rollback changes if failed
        if (mode === 'create') {
          setOrderLineItem(null)
          onLineItemChange?.(null)
        } else if (mode === 'update' && orderLineItem != null && originalQuantity != null) {
          setOrderLineItem({ ...orderLineItem, quantity: originalQuantity })
          onLineItemChange?.({ ...orderLineItem, quantity: originalQuantity })
        }
      }
    },
    [orderLineItem, cache, onLineItemChange]
  )

  // Adds a new line item to the order
  const addItem = useCallback(
    async (productId: string, varietyId: string, isNewOrder = false) => {
      // Should not actually be null at this point, but safety check
      if (order.current?.id == null) return

      const lineItem = { productId, varietyId, quantity: minQuantityStart }
      setOrderLineItem(lineItem)
      onLineItemChange?.(lineItem)

      const refetchQueries = isNewOrder
        ? [GET_DRAFT_ORDERS_SUMMARY, GET_DRAFT_ORDERS, GET_OLDEST_DRAFT_AND_LAST_POSTED_ORDERS]
        : undefined

      const result = await updateDraftOrderLineItem({
        variables: {
          input: { orderId: order.current?.id, attributes: { ...lineItem } }
        },
        refetchQueries
      })

      updateCache({ result, productId, varietyId }, { mode: 'create' })

      return result
    },
    [updateDraftOrderLineItem, minQuantityStart, updateCache, onLineItemChange]
  )

  // Updates the quantity on an order line item
  //
  // NOTE:
  //
  // This is debounced with 'leading' option, so it _will_ be called
  // immediately, however subsequent calls will not be triggered until at least
  // a 300 milliseconds gap between consequtive calls is reached. Clicking
  // really fast continuously will just keep extending the time until the next
  // function is actually executed.
  //
  // 300 milliseconds should be enough time for the backend request to complete
  // successfully, so in effect we never end-up with multiple backend requests
  // executing at once.
  const updateQuantity = useDebouncedCallback(
    useCallback(
      async (productId: string, varietyId: string, quantity: number) => {
        let isNewOrder = false
        if (order.current?.id == null) {
          isNewOrder = Boolean(await setupDraftOrder())
        }

        // Should not actually be null at this point, but safety check
        if (order.current?.id == null) return
        let result

        if (orderLineItem) {
          const originalQuantity = orderLineItem.quantity
          let newQuantity = Math.max(minQuantityStart, quantity)
          newQuantity = newQuantity === orderLineItem.quantity ? 0 : newQuantity
          const line = { productId, varietyId, quantity: newQuantity }

          // premptive update
          setOrderLineItem({ id: orderLineItem.id, ...line })
          onLineItemChange?.({ id: orderLineItem.id, ...line })

          result = await updateDraftOrderLineItem({
            variables: { input: { orderId: order.current.id, id: orderLineItem.id, attributes: line } }
          })

          updateCache(
            { result, productId, varietyId },
            { mode: 'update', originalQuantity, desiredQuantity: newQuantity }
          )
        } else {
          result = await addItem(productId, varietyId, isNewOrder)
        }

        return result
      },
      [
        updateDraftOrderLineItem,
        minQuantityStart,
        addItem,
        orderLineItem,
        setupDraftOrder,
        updateCache,
        onLineItemChange
      ]
    ),
    300,
    { leading: true }
  )

  // Increments the quantity on an order line item
  const increment = useDebouncedCallback(
    useCallback(async () => {
      const isNewOrder = await setupDraftOrder()
      if (productId == null || varietyId == null) return

      if (orderLineItem) {
        updateQuantity(productId, varietyId, orderLineItem.quantity + minQuantityChange)
      } else {
        addItem(productId, varietyId, isNewOrder)
      }
    }, [addItem, updateQuantity, minQuantityChange, orderLineItem, productId, varietyId, setupDraftOrder]),
    300,
    { leading: true }
  )

  // Decrements the quantity on an order line item
  const decrement = useDebouncedCallback(
    useCallback(() => {
      if (productId == null || varietyId == null) return

      if (orderLineItem) {
        updateQuantity(productId, varietyId, orderLineItem.quantity - minQuantityChange)
      }
    }, [updateQuantity, minQuantityChange, orderLineItem, productId, varietyId]),
    300,
    { leading: true }
  )

  const isBuyerBanned = ordersData?.currentBuyer.status === BuyerStatusEnum.BANNED

  if (orderLoading || ordersLoading || isBuyerBanned) return null

  return (
    <div className={classnames(styles.quantityControls, { [styles.expand]: expand })}>
      {isStack ? (
        <button className={styles.stackButton} onClick={handleStackClick}>
          <Icon kind="more-horizontal" size={16} />
        </button>
      ) : (
        <>
          <button
            className={styles.quantityButton}
            tabIndex={-1}
            disabled={!inOrder || !productId || !varietyId || disabled}
            onClick={decrement}>
            <Icon kind="minus" size={16} />
          </button>
          <button
            className={styles.quantityButton}
            tabIndex={-1}
            onClick={increment}
            disabled={!productId || !varietyId || disabled}>
            <Icon kind="plus" size={16} />
          </button>
        </>
      )}
      <div className={styles.quantityInputWrapper}>
        <input
          className={styles.quantityInput}
          disabled={isStack || !productId || !varietyId || disabled}
          type="text"
          placeholder="0"
          value={(orderLineItem && orderLineItem.quantity) || 0}
          onChange={async ({ target: { value } }) => {
            if (productId == null || varietyId == null) return
            updateQuantity(productId, varietyId, Number(value))
          }}
        />
      </div>
    </div>
  )
}

export default AddToCartButton
